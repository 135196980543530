import React from 'react';
import Layout from '../../components/Layout';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "start",
    "& h1": {
      marginBottom: theme.spacing(4),
    },
    margin: `${theme.spacing(0)}px ${theme.spacing(8)}px ${theme.spacing(16)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      "& h2": {
        marginBottom: theme.spacing(4),
      },
    },
  },
  title: {
    width: "100%",
    textAlign: "center"
  },
  infoContainerTitle: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(2)}px !important`, 
  },
  infoContainerRubrique: {
    marginBottom: 0,
  },
  infoContainerLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  infoContainerList: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    '&not(:last-of-type)': {
      marginBottom: 0,
    },
    '@media (max-width: $breakpoint-sm)': { 
      flexDirection: 'column',
    }
  },
  infoContainerListItem: {
    '&:not(:last-of-type)': {
      '&::after': {
        content: "' • '",
      },
    },
    '@media (max-width: $breakpoint-sm)': {  
      '&:not(:last-of-type)': {
        '&::after': { 
          content: 'none',
        }
      }
    }
  },
  infoContainerCopyRight: {
    fontWeight: 600,
    fontSize: theme.spacing(3),
  }
}));

const MentionsPage = () => {
  let year = new Date();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    console.log(isMobile) ||
    <Layout pageName="Mentions">
      <div className={classes.title}>
          <Typography variant="h1">Mentions légales</Typography>
        </div>
        <Grid
          container
          direction="column"
          justifyContent="stretch"
          alignContent="center"
          className={classes.container}
        >
        <Grid
          item
          xs={12} sm={10} md={10} lg={8} xl={5} 
          id="#mentions" 
          className={classes.infoContainer}
        >
          <div className={classes.infoContainerText}>
            {/* <Typography className={classes.infoContainerTitle} variant="h2">Mentions légales</Typography> */}
            <Typography className={classes.infoContainerTitle} variant="h3">Contacts</Typography>
            <Typography variant="body1">Tu peux nous envoyer un email ici&#160;: </Typography>
            <a className={classes.infoContainerLink} href="mailto:contact@surboom.fr">
              contact[at]surboom[dot]fr
            </a>
            <br />
            <Typography variant="body1">ou bien nous écrire là&#160;: </Typography>
            <Typography variant="body1">
              Marianne Mannani - La Maison de l'initiative
              <br />
              52, rue Jacques Babinet - 31100 Toulouse
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12} sm={10} md={10} lg={8} xl={5} 
          id="#crédits" 
          className={classes.infoContainer}
        >
          <div className={classes.infoContainerText} >
            <Typography className={classes.infoContainerTitle} variant="h3" >Crédits</Typography>
            <ul className={classes.infoContainerList} >
              <li className={classes.infoContainerListItem}>Travail d'écriture</li>
              <li className={classes.infoContainerListItem}>Rédaction</li>
              <li className={classes.infoContainerListItem}>Blagounettes</li>
            </ul>
            <Typography variant="body1"> ↳ ©Justine Bonno - Maison Ivy (
              <a 
                className={classes.infoContainerLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                href="https://www.maisonivy.fr"
              >
                www.maisonivy.fr
              </a>
            )
            </Typography>
            <ul className={classes.infoContainerList} >
              <li className={classes.infoContainerListItem}>Design du site</li>
              <li className={classes.infoContainerListItem}>Développement</li>
              <li className={classes.infoContainerListItem}>Prises de vues</li>
            </ul>
            <Typography variant="body1"> ↳ ©Marianne Mannani (
              <a 
                className={classes.infoContainerLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                href="https://mannani.fr"
              >
                www.mannani.fr
              </a>
            )
            </Typography>
            <Grid
              item
              xs={12} 
              id="#responsableEdition" 
              className={classes.infoContainer}
            >
              <Typography className={classes.infoContainerTitle} variant="h3">Responsable d'édition</Typography>
              <Typography variant="body1">Marianne Mannani, membre de la Maison de l'Initiative,
                SCOP SA à capital variable{!isMobile && <br />} dont le siège social est situé au 52 rue Jacques Babinet - 31100&#160;Toulouse, 
                {!isMobile && <br />} immatriculée au Registre du Commerce et des Sociétés de Toulouse (n°398 386 102)
              </Typography>
              <Typography variant="body1" display="block">N° TVA intra-comunautaire&#160;: FR 75 398 386 102</Typography>
              <br />
              <Typography variant="body1" display="block" className={classes.infoContainerRubrique}>contact Maison de l'initiative&#160;: </Typography>
                <ul className={classes.infoContainerList} >
                  <li className={classes.infoContainerListItem}>
                    <a className={classes.infoContainerLink} href="tel:+534638110">
                      05 34 63 81 10
                    </a>
                  </li>
                  <li className={classes.infoContainerListItem}>
                    <a className={classes.infoContainerLink} href="mailto:cae@maisoninit.coop">
                      cae@maisoninit.coop
                    </a>
                  </li>
                  <li className={classes.infoContainerListItem}>
                    <a className={classes.infoContainerLink} target="_blank" rel="noopener noreferrer" href="https://www.maison-initiative.org">
                      www.maison-initiative.org
                    </a>
                  </li>
                </ul>
              <Typography variant="body1" display="block" >Hébergement&#160;: Netlify (
                <a className={classes.infoContainerLink} target="_blank" rel="noopener noreferrer" href="https://www.netlify.com/">
                  www.netlify.com
                </a>
              )</Typography>
              <br />
              <Typography className={classes.infoContainerCopyRight} variant="body1">{`Copyright ${year.getFullYear()}`}</Typography>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};
export default MentionsPage;